<template>
  <main
    id="main"
    class="page"
    :class="[
      `page-${slug}`,
      { 'page-alt-layout theme-alt': page && page.options.altLayout },
    ]"
  >
    <AtomsScrollDetector />
    <AtomsLoader v-if="pending || !page" class="page-loader" />
    <TransitionGroup v-else>
      <PageHeader key="header" v-bind="page.heading" />
      <BlocksSection
        v-for="section in page.body"
        :key="section._key"
        v-bind="section"
      />
      <SiteFooter key="footer" />
    </TransitionGroup>
  </main>
</template>

<script lang="ts" setup>
import type { Page } from '~/types'
const { params } = useRoute()

// const preview = computed(() => Object.keys(query).includes('preview'))

definePageMeta({
  validate: async (route) => {
    const { $routes, $getSlug } = useNuxtApp()
    const slug = $getSlug(route.params.page)
    // console.log(
    //   'slug',
    //   slug,
    //   !!$routes['page_' + slug] ||
    //     !!$routes['article_' + slug] ||
    //     !!$routes['story_' + slug]
    // )
    return (
      !!$routes['page_' + slug] ||
      !!$routes['article_' + slug] ||
      !!$routes['story_' + slug]
    )
  },
})

const { $getSlug } = useNuxtApp()

const slug = computed(() => $getSlug(params.page))
// const searchQuery = computed(() => preview.value ? `_id == $slug` : `slug.current == $slug`)

const { transform, theme } = useBody()

const { data: page, pending } = useSanityQuery<Page>(
  useGroq.page,
  { slug: slug.value },
  {
    // @ts-ignore
    transform,
  }
)
// const nuxtApp = useNuxtApp()
// onMounted(() => {
//   if (!document) return
//   nuxtApp.hook('page:finish', () => {
//     console.log(
//       'rect',
//       document.getElementById('key-documents')?.getBoundingClientRect()
//     )
//   })
// })
watch(
  useRoute() && page,
  () => (theme.value = page.value?.options.altLayout ? 'alt' : ''),
  { immediate: true }
)

useHead(() => page.value?.head)
</script>

<style lang="scss">
.page {
  position: relative;

  &-loader {
    margin: auto;
  }

  &-cards .container {
    width: fit-content;
    .column {
      max-width: 20rem;
    }

    grid-gap: 2rem;
    justify-content: center;

    .second {
      margin-top: 5vh;
    }
    .third {
      margin-top: 10vh;
    }
  }
}
</style>
